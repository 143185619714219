<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManagementSurveyListSolutions',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Modal: () => import('@/components/general/Modal')
  },
  data () {
    return {
      solutions: [],
      programs: [],
      surveyName: null
    }
  },
  methods: {
    ...mapActions([
      'setFetching',
      'attemptListSolutionsSurvey',
      'attemptListProgramsSurvey',
      'attemptGetSurvey'
    ]),
    close () {
      this.$router.push({ name: 'management.survey' })
    },
    goToSolution (idSolution) {
      const elem = this.$refs['linkToSolution' + idSolution]
      elem.$el.click()
    },
    goToProgram (idProgram) {
      const elem = this.$refs['linkToProgram' + idProgram]
      elem.$el.click()
    },
    fetchSurvey () {
      return this.attemptGetSurvey(this.$route.params.id).then(({ data }) => {
        this.surveyName = data.name
        return true
      })
    },
    fetchSolutions () {
      return this.attemptListSolutionsSurvey(this.$route.params.id).then((data) => {
        this.solutions = data || []
      }).catch(() => {
        this.solutions = []
      }).finally(() => {
        return true
      })
    },
    fetchPrograms () {
      return this.attemptListProgramsSurvey(this.$route.params.id).then((data) => {
        this.programs = data || []
      }).catch(() => {
        this.programs = []
      }).finally(() => {
        return true
      })
    }
  },
  created () {
    this.setFetching(true)
    Promise.all([
      this.fetchSurvey(),
      this.fetchSolutions(),
      this.fetchPrograms()
    ]).finally(() => {
      this.setFetching(false)
    })
  }
}
</script>
<template>
  <modal
    :active="true"
    :cancel="true"
    @close="close"
  >
    <div class="center management-content">
      <span class="modal-subtitle">{{ $t('management.survey:solutions.list.title') }}</span>
      <span class="modal-subtitle survey-name">{{ surveyName }}</span>
      <datatable
        v-if="solutions && solutions.length > 0"
        :items="solutions"
        dark
      >
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('global:form.solution') }}
            </th>
            <th class="th align-right">
              {{ $t('management.survey:solutions.list.active') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr
            class="highlight"
            title="Acessar"
            @click="goToSolution(props.item.id)"
          >
            <td class="td">
              <span class="td-text bolder">
                <router-link
                  v-show="false"
                  :ref="'linkToSolution' + props.item.id"
                  :to="{ name: 'solutions.manage', params: { id: props.item.id, tab: 1 } }"
                  target="_blank"
                />
                {{ props.item.name }}
              </span>
            </td>
            <td class="td align-right">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
          </tr>
        </template>
      </datatable>

      <datatable
        v-if="programs && programs.length > 0"
        :items="programs"
        dark
      >
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('global:form.program') }}
            </th>
            <th class="th align-right">
              {{ $t('programs.list:datatable.header.3') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr
            class="highlight"
            title="Acessar"
            @click="goToProgram(props.item.id)"
          >
            <td class="td">
              <span class="td-text bolder">
                <router-link
                  v-show="false"
                  :ref="'linkToProgram' + props.item.id"
                  :to="{ name: 'programs.manage', params: { id: props.item.id, tab: 1 } }"
                  target="_blank"
                />
                {{ props.item.name }}
              </span>
            </td>
            <td class="td align-right">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
          </tr>
        </template>
      </datatable>

      <p v-if="solutions.length == 0 && programs.length == 0" class="solutions-programs-not-found">{{ $t('management.survey:solutions.programs.not.found') }}</p>
    </div>
  </modal>
</template>

<style>
.modal-subtitle.survey-name {
  color: #ffffffcc;
}

.align-right {
  text-align: right !important;
}

.highlight:hover{
  background-color: rgb(120, 179, 247);
  cursor: pointer;
}

a,
a:hover,
a:active {
  color: #FFFFFF;
}

.solutions-programs-not-found {
  color: #ffffff;
}
</style>
